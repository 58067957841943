/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import { Button, Column, Input, Row } from "../components/defaults";
import api from "../utils/api";
import { ImageInput } from "../components/image-input";
import { useEffect } from "react";
import { useState } from "react";
import { isInvalidEmail } from "../utils/validator";
import { NotificationManager } from "react-notifications";
import loadingIcon from '../images/3-dots-move-purple.svg';

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Satoshi-Medium'
`;

const Disclaimer = styled.div`
    font-size: 12px;
    font-family: 'Satoshi-Regular';
`;

const Error = styled.div`
    color: red;
    font-size: 15px;
`;

const validateEmail = (field, value, setError) => {
    if (isInvalidEmail(value)) {
        setError(`Invalid ${field}`);
        return false;
    }
    return true;
}


const keys = [
    { id: 'name', label: 'Brand Name' },
    { id: 'website', label: 'Website' },
    { id: 'support_email', label: 'Support Email', validation: validateEmail },
]

const TabSelectorWrapper = styled(Row)`
    gap: 5px;
`;

const Tab = styled.div`
    ${props => props.selected ? `color: ${SELECTED_COLOR};` : 'color: rgba(0,0,0,.8);'}
    ${props => props.selected && `background: rgba(0,0,0,0.05);`};
    ${props => !props.selected && `cursor: pointer;`};
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
`;

const TabSelector = ({ tab, setTab }) => {

    return <TabSelectorWrapper>
        <Tab onClick={() => setTab('logo')} selected={tab==='logo'}>Logo</Tab>
        <Tab onClick={() => setTab('details')} selected={tab==='details'}>Details</Tab>
    </TabSelectorWrapper>

}

const BrandSettingsLogo = ({ data, loading }) => {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoFile, setLogoFile] = useState(null);

    useEffect(() => {
        setLogo(data.logo_url);
    }, [data])

    const submitLogo = async () => {
        setError(null)
        setSubmitting(true);
        if (!logo) return setError('Please upload a logo');
        try {
            const formData = new FormData();
            formData.append('logo', logoFile);
            await api.post('/admin/brand-settings', formData);
            setError(null);
            NotificationManager.success('Brand Settings successfully updated.')

        } catch (e) {
            setError(e.response.data.message);
        }
        setSubmitting(false);
    }

    const disabled = submitting || loading || !logoFile;

    const onSuccessUpload = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setLogoFile(file);
            setLogo(reader.result)
        };
        reader.readAsDataURL(file);
    }

    return <Column style={{ gap: 15 }}>

        <ImageInput 
        onStart={() => setError(null)}
        onSuccess={onSuccessUpload}
        existingImage={logo}
        onError={(e) => setError(e)} />
        <Row style={{ justifyContent: 'space-between' }}>
            <Error>{error}</Error>
            <Button 
            style={{ marginBottom: 30, alignSelf: 'flex-end' }}
            disabled={disabled} 
            onClick={submitLogo}>
                Update Logo
            </Button>
        </Row>
    </Column>
    


}



export const BrandSettingsDetails = ({ loading, data }) => {

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [values, setValues] = useState({});

    const validate = () => {
        for (let key of keys) {
            if (!values[key.id]) {
                setError('Please fill all fields');
                return false;
            }
            if (key.validation && !key.validation(key.label, values[key.id], setError)) {
                return false;
            }
        }
        return true;
    }

    const submit = async () => {
        setError(null)
        if (!validate()) return;
        setSubmitting(true);
        try {
            await api.post('/admin/brand-settings', values);
            setError(null);
        } catch (e) {
            setError(e.response.data.message);
        }
        NotificationManager.success('Brand settings successfully updated.')
        setSubmitting(false);
    }

    const update = (id, event) => {
        setValues((existing) => ({
            ...existing,
            [id]: event.target.value
        }))
    }

    useEffect(() => {
        if (data) {
            for (let key of keys) {
                setValues((vals) => ({ ...vals, [key.id]: data[key.id] }));   
            }
        }
    }, [data])

    const disabled = submitting || loading;

    return <Column style={{ gap: 15 }}>
        {keys.map(({ id, label }) => <Input onChange={(e) => update(id, e)} value={values[id]} key={id} label={label} placeholder={label} />)}
        <Disclaimer>Note: As for the reports, these updates will be reflected only on newly generated ones</Disclaimer>
        <Row style={{ marginTop: 20, justifyContent: 'space-between' }}>
            <Error>{error}</Error>
            <Button 
            disabled={disabled} 
            onClick={submit}>
                Update Brand Details
            </Button>
        </Row>
    </Column>

}


export const BrandSettings = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [tab, setTab] = useState('logo');

    const fetch = async () => {
        const res = await api.get('/admin/brand-settings')
        setData(res.data);
        setLoading(false);
    }

    useEffect(() => {
        fetch()
    }, []);

    if (loading) return <Column style={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <img src={loadingIcon} alt='loading' />
    </Column>

    return <Contents>
        <Row style={{ marginBottom: 30 }}>
            <TopTitle>Brand Settings</TopTitle>
            <div style={{ flex: 1 }} />
            <TabSelector tab={tab} setTab={setTab} />
        </Row>
        {tab === 'logo' && <BrandSettingsLogo data={data} loading={loading} />}
        {tab === 'details' && <BrandSettingsDetails data={data} loading={loading} />}
    </Contents>

}
