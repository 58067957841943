import axios from 'axios';
import { getAuth } from 'firebase/auth';

const api = axios.create({ baseURL: 
    process.env.REACT_APP_API_URL || 
    `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`});

api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    async (config) => {
        return config;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            window.location.href = '/sign-in';
            localStorage.removeItem('token');
            getAuth().signOut();
        }
        return Promise.reject(error);
    }
)

export default api;