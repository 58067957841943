/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import papaparse from 'papaparse'
import { Modal } from './modal'
import styled from 'styled-components'
import { Button, Column, Row } from './defaults'
import api from '../utils/api'

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    input {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const Input = ({ label, ...inputProps }) => {

    return <InputWrapper>
        <label>{label}</label>
        <input {...inputProps} />
    </InputWrapper>

}

const PrimaryButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Wrapper = styled(Column)`
    height: ${props => props.hasImage ? '500px' : '340px'};
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 10px;
    align-items: center;
    justify-content: center;

    p {
        font-size: 13px;
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px;
        color: rgba(0,0,0,.6);
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export function MockResultsModal({ 
    barcode,
    type,
    setVisible
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSubmit = async ({ results }) => {
        setLoading(true);
        setError(null)
        try {
            await api.post('/admin/mock-kit-result', {
                barcode,
                type,
                data: results
            })
            setVisible()
        } catch (err) {
            console.log(err?.response?.data?.title)
            if (err?.response?.data?.title) {
                setError(err?.response?.data?.title)    
            } else {
                setError("Error to mock result! Check the network tab")
            }
        }
        setLoading(false)
    }

    const AgeForm = ({ setVisible, onSubmit, error, loading }) => {
        const [biologicalAge, setBiologicalAge] = useState()
        const [chronologicalAge, setChronologicalAge] = useState()
        const [methylationLevel, setMethylationLevel] = useState()
        const [isDisabled, setIsDisabled] = useState(true)
        
        useEffect(() => {
            if (biologicalAge && chronologicalAge && methylationLevel) {
                setIsDisabled(false)
                return
            }
            setIsDisabled(true)
        }, [biologicalAge, chronologicalAge, methylationLevel])

        return <FormSection>
            <Input label='Chronological Age' value={chronologicalAge} onChange={(e) => setChronologicalAge(e.target.value)} />
            <Input label='Biological Age' value={biologicalAge} onChange={(e) => setBiologicalAge(e.target.value)} />
            <Input label='Methylation Level' value={methylationLevel} onChange={(e) => setMethylationLevel(e.target.value)} />
            <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button onClick={() => setVisible(null)}>Cancel</Button>
                <PrimaryButton disabled={isDisabled} loading={loading} onClick={() => onSubmit({ results: { biologicalAge, methylationLevel, chronologicalAge } })}>Confirm</PrimaryButton>
            </Row>
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </FormSection>
    }

    const FolateForm = ({ setVisible, onSubmit, error, loading }) => {
        const [results, setResults] = useState()
        const [file, setFile] = useState()
        const onError = () => {alert('Error to process the file')}
        const onStart = () => {}
        const onDrop = useCallback((acceptedFiles, fileRejections) => {
            onStart()
            if (fileRejections.length > 0) {
                onError(fileRejections[0].errors[0].message);
                return;
            }
            const file = acceptedFiles[0];
            if (!file) {
                return;
            } else {
                setFile(file)
            }
        }, [])

        useEffect(() => {
            readCSV(file)
        }, [file])

        const readCSV = async (file) => {
            if (!file) return;

            papaparse.parse(file, {
                complete: (result) => {
                    const data = result.data;
                    const processedResults = {};

                    data.forEach((row) => {
                        if (row.length < 3) return;

                        const rs_id = row[1];
                        const alleles = row.slice(2, 4);
                        processedResults[rs_id] = alleles;
                    });

                    setResults(processedResults);
                },
                header: false,
                skipEmptyLines: true,
            });
        }

        const {
            getRootProps, 
            getInputProps, 
            isDragActive
        } = useDropzone({
            onDrop,
            accept: {
                'text/*': ['.csv']
            },
            maxFiles: 1,
        })

        return <FormSection>
            <Wrapper {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                    <p>Drop the csv here ...</p> :
                    <p>Drag 'n' drop your file here, or click to select csv, then click on update to confirm.</p>
                }
                {
                    file && file?.name
                }
            </Wrapper>
            {results && <span style={{ color: 'green' }}>File processed. Click to submit!</span>}
            <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button onClick={() => setVisible(null)}>Cancel</Button>
                <PrimaryButton disabled={!results} loading={loading} onClick={() => onSubmit({ results })}>Confirm</PrimaryButton>
            </Row>
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </FormSection>
    }

    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Mock Result {type}</Title>
            </TopSection>
            { type === 'Age' ? 
                <AgeForm onSubmit={onSubmit} setVisible={setVisible} error={error} loading={loading} /> :
                <FolateForm onSubmit={onSubmit} setVisible={setVisible} error={error} loading={loading} /> 
            }
        </Box>
    </Modal>

}