/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Column, Row } from "../components/defaults";
import ContentLoader from 'react-content-loader'
import { Dropdown } from "./dropdown";
import { Paginator } from "./paginator";
import sort from '../images/sort.svg'
import ColumnQuery from "./query";
import api from "../utils/api";
import queryString from 'query-string';
import moment from "moment";


const mapData = (data) => ({
    code: data.barcode,
    user_id: data.user_id,
    status: data.status,
    test: data.test_types[0]?.name,
    type: data.type,
    chronological_age: data?.chronological_age?.toFixed(2),
    biological_age: data.health_result?.biological_age?.toFixed(2),
    brand: data.brand?.name,
    laboratory: data.laboratory?.name,
    laboratory_number: data.laboratory_number,
    bundle: data.bundle,
    sample_date: data.scanned_at ? moment(data.scanned_at).format('MM-DD-YYYY') : '-',
    received_date: data.scanned_at ? moment(data.scanned_at).format('MM-DD-YYYY') : '-',
    shopify_order_number: data.shopify_order?.shopify_order_number,
    shopify_order_date: data.shopify_order?.created_at,
    shopify_order_status: data.shopify_order?.tracking_status,
    kit_id: data.id,
})


const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const Grid = styled.div`
    grid-auto-rows: 72px;
    grid-template-columns: ${props => `repeat(${props.cellCount}, 1fr)`};
    display: grid;
    min-width: 1000px;
`;

const CellWrapper = styled(Row)`
    align-self: center;
    color: ${SELECTED_COLOR};
    font-size: 16px;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(231, 231, 233, 1);
    align-self: stretch;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    div {
        font-family: 'Satoshi-Bold';
        white-space: nowrap;
    }
`;

const HeaderCellWrapper = styled(CellWrapper)`
    color: rgba(5, 31, 115, 1);
    background: rgba(249, 249, 249, 1);
    align-items: center;
`;

const Cell = ({children, style = {}}) => {
    return <CellWrapper style={style}>
        <Row>{children}</Row>
    </CellWrapper>
}

const HeaderCell = ({children, style = {}}) => {
    return <HeaderCellWrapper style={style}>
        <div>{children}</div>
    </HeaderCellWrapper>
}


const TableRow = ({
    values,
    columns
}) => {
    return columns.map(({ id, formatter }, i) => <Cell style={i === 0 ? { paddingLeft: 24 } : i === columns.length - 1 ? { paddingRight: 24 } : {}}>
        { formatter ? formatter(values) : values[id] || '-'} 
    </Cell>)   
}

const Loader = () => <ContentLoader viewBox="0 0 150 30">
    <rect x="0" y="0" rx="4" ry="4" width="150px" height="30px" />
</ContentLoader>


const LoadingCell = () => {

    return <CellWrapper style={{}}>
        <Loader />
    </CellWrapper>

}

const TableRowLoading = ({ columns }) => {
    return columns.map((_, i) => <LoadingCell style={i === 0 ? { paddingLeft: 24 } : i === columns.length - 1 ? { paddingRight: 24 } : {}} />) 
}


const SortIcon = styled.img`
    height: 12px;
    cursor: pointer;
    opacity: ${props => props.active ? 1 : 0.2};
`;

const TableHeader = ({
    columns,
}) => {
    const [columnQueryModal, setColumnQueryModal] = useState(null)
    const {fieldSort, fieldQuery} = useContext(TableContext);

    const sortedFields = (fieldSort) ? Object.keys(fieldSort) : [];
    const queryFields = (fieldQuery) ? Object.keys(fieldQuery): [];

    return <>
    {columnQueryModal && <ColumnQuery setVisible={() => setColumnQueryModal(null)} {...columnQueryModal} />}
    {columns.map(({ title, disableQuery, disableSorting, ...data }, i) => <HeaderCell style={i === 0 ? { paddingLeft: 24 } : i === columns.length - 1 ? { paddingRight: 24 } : {}}>
        { title } {!(disableSorting && disableQuery) && title  ? <SortIcon active={sortedFields.includes(data.id) || queryFields.includes(data.id)} src={sort} onClick={() => setColumnQueryModal({title, disableQuery, disableSorting, ...data})} alt='Filter' /> : ''}
    </HeaderCell>)}
    </> 
}

const NoContent = styled(Column)`
    flex: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const TableWrapper = styled(Column)`
    overflow: scroll;
`;

const Footer = styled(Row)`
    margin-top: 20px;
`;

const Showing = styled(Row)`
    align-items: center;
    color: rgba(5, 31, 115, 1);
    div {
        margin-left: 10px;
    }
`;

export const TableContext = React.createContext({});

const deconstructQueryObject = (query, prefix) => {
    return Object.entries(query).reduce((prev, [key, value]) => {
        if (value?.value) return {...prev, [`${prefix}${key}`]: value.value }
        else return {...prev, [`${prefix}${key}`]: value }
    }, {})
}


export const TableContextWrapper = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [fieldSort, setFieldSort] = useState({});
    const [fieldQuery, setFieldQuery] = useState({});
    const [query, setQuery] = useState('');
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState([{ value: 8, label: 8 }]);

    const showNext = () => {
        setSkip((s) => s + limit[0].value);
    }

    const showPrev = () => {
        setSkip((s) => s - limit[0].value)
    }

    const setShowing = (v) => {
        setSkip(0);
        setLimit(v)
    }

    const fetchKits = async (status) => {
        setLoading(true)

        const params = {
            ...(query && { query_search: query }),
            skip,
            limit: limit[0]?.value,
            ...(fieldQuery ? deconstructQueryObject(fieldQuery, 'query_') : {}),
            ...(fieldSort ? deconstructQueryObject(fieldSort, 'sort_') : {}),
        }

        if (status) {
            params.query_status = status;
        }

        const results = await api.get(`/admin/overview?${queryString.stringify(params)}`);        
        setData(results.data?.results?.map(mapData));
        setCount(results.data?.total);
        setLoading(false)
    };

    console.log(data)
        

    return <TableContext.Provider value={{
        query,
        setQuery,
        skip,
        showNext,
        showPrev,
        limit,
        setLimit,
        setShowing,
        setSkip,
        fieldQuery,
        setFieldQuery,
        fieldSort,
        setFieldSort,
        loading,
        setLoading,
        data,
        fetchKits,
        count,
    }}>
        {children}
    </TableContext.Provider>
}

export const Table = ({
    data, 
    loading, 
    columns, 
    hidePagination,
    selectedColumns
}) => {
    if (!selectedColumns && columns) {
        selectedColumns = columns().map((c) => c.id)
    }

    const [prevCount, setPrevCount] = useState(5);
    const {
        limit,
        showNext,
        showPrev,
        skip,
        setShowing
    } = useContext(TableContext)

    useEffect(() => {
        setPrevCount(data?.length, 5)
    }, [data])

    const columnsFilter = (c) => selectedColumns.includes(c.id)
    
    return <>
        <TableWrapper>
            <Column style={{ minHeight: '50vh'}}>
            <Grid cellCount={selectedColumns.length} loading={loading}>
                <TableHeader columns={columns().filter(columnsFilter)} />
                {loading ? new Array(Math.max(5, prevCount)).fill(1).map((a = {}) => <TableRowLoading columns={columns().filter(columnsFilter)} />) :
                data.map((d, i) => <TableRow values={d} columns={columns(d.code).filter(columnsFilter)} key={i} {...d}/>)}
            </Grid>
            {(!loading && !data?.length) && <NoContent>
                <div>No data found</div>
            </NoContent>}
            </Column>
        </TableWrapper>
        {!hidePagination && <Footer>
        <Showing>
            Showing
            <Dropdown value={limit} setValue={setShowing} options={[
                { value: 8, label: 8 },
                { value: 16, label: 16 },
                { value: 32, label: 32}
            ]} />
            </Showing>
            <div style={{ flex: 1 }} />
            <Paginator
            nextDisabled={data?.length < limit[0]?.value}
            skip={skip} 
            showPrev={showPrev} 
            showNext={showNext} />
        </Footer>}
    </>
    
}







