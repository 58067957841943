import React, { useState } from 'react';
import profile from '../images/profile.svg';
import styled from 'styled-components';
import { signOut, getAuth, signInWithCustomToken } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { clear } from '../redux/reducer/user';
import useAuth from '../utils/useAuth';
import { useNavigate } from 'react-router-dom';
import { Button, Column, Input } from './defaults';
import api from '../utils/api';
import loadingIcon2 from '../images/3-dots-move-purple.svg';

import { NotificationManager } from 'react-notifications';
import { Modal } from './modal';
import { useEffect } from 'react';

const Wrapper = styled.div`
    position: relative;
    z-index: 99;
`;

const ProfileIcon = styled.img`
    height: 30px;
    width: 30px;
    cursor: pointer;
`;

const Ribbon = styled(Column)`
    position: absolute;
    right: 0px;
    gap: 20px;
    top: 35px;
    border-radius: 20px;
    background: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,.5);
    z-index: 100;
    padding: 20px;
`;

const Mask = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    background: rgba(0,0,0,.3);
`;

const ItemWrapper = styled(Column)`
    white-space: nowrap;
    cursor: pointer;
    height: 20px;
    width: 200px;
    justify-content: center;
`;

const LoadingIcon = styled.img`
    height: 20px;
    align-self: flex-start;
`;


const Box = styled(Column)`
    border-radius: 24px;
    background: white;
    padding: 20px;
    padding: 32px 40px;
    width: 400px;
`;

const BoxTitle = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const Item = ({children, loading, ...props}) => {

    return <ItemWrapper {...props}>
        {loading ? <LoadingIcon src={loadingIcon2} /> : children}
    </ItemWrapper>

}   


const SelectUserToImpersonateModal = ({ setVisible }) => {

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        try {
            setLoading(true)
            const response = await api.post('/admin/impersonate-user', { email });
            window.open(response.data?.url, '__blank')
            setLoading(false)
            setVisible(false)
        } catch (err) {
            NotificationManager.error('An error happened.')
        };
        setLoading(false)
    }

    return <Modal onClickOutside={setVisible}>
        <Box onClick={(e) => e.stopPropagation()}>
            <BoxTitle style={{ marginBottom: 15 }}>Enter an user email to impersonate</BoxTitle>
            <Input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button loading={loading} disabled={!email} onClick={submit} style={{ alignSelf: 'flex-end', marginTop: 15 }}>
                Impersonate
            </Button>
        </Box>
    </Modal>

}

export const UserRibbon = () => {

    const [loading, setLoading] = useState(false);
    const [ribbonVisible, setRibbonVisible] = useState(false);
    const [impersonationModalVisible, setImpersonationModalVisible] = useState(false);
    const dispatch = useDispatch();
    const { claims }= useAuth();
    const navigate = useNavigate();

    const onClickSignOut = async () => {
        await signOut(getAuth())
        localStorage.removeItem('token');
        dispatch(clear());
    }
    
    useEffect(() => {
        if (impersonationModalVisible) setRibbonVisible(false)
    }, [impersonationModalVisible])

    const stopImpersonating = async () => {
        try {
            setLoading(true)
            const response = await api.post('/admin/other/remove');
            await signInWithCustomToken(getAuth(), response.data.new_token);
            const token = await getAuth().currentUser.getIdToken();
            await localStorage.setItem('token', token);
            NotificationManager.success(`Stopped impersonating` );
            navigate('/sign-in');
        } catch (err) {
            NotificationManager.error('An error happened')
        }
        setLoading(false)
    }

    const openBrands = () => {
        setRibbonVisible(false)
        navigate('/brands')
    }

    const openLogs = () => {
        setRibbonVisible(false)
        navigate('/logs')
    }

    const openRejuvantKits = () => {
        setRibbonVisible(false)
        navigate('/dashboard/rejuvant-kits')
    }

    return <Wrapper>
        {impersonationModalVisible && <SelectUserToImpersonateModal setVisible={setImpersonationModalVisible} />}
        <ProfileIcon onClick={() => setRibbonVisible(true)} src={profile} />
        {ribbonVisible && <Mask onClick={() => setRibbonVisible(false)} />}
        {ribbonVisible && <Ribbon>
            {claims?.role === 'SUPER_ADMIN' && <Item onClick={() => navigate('/root/users')}>Manage Users</Item>}
            {claims?.reset_override && <Item loading={loading} onClick={() => stopImpersonating()}>Stop Impersonating Admin</Item>}
            {claims?.role === 'SUPER_ADMIN' && claims?.brand === 1 && <Item onClick={() => setImpersonationModalVisible(true)}>Impersonate User</Item>}
            {claims?.brand === 1 && ['SUPER_ADMIN'].includes(claims?.role) && <Item onClick={openBrands}>Brands</Item>}
            {claims?.brand === 1 && ['SUPER_ADMIN'].includes(claims?.role) && <Item onClick={openLogs}>Logs</Item>}
            {claims?.brand === 1 && ['SUPER_ADMIN', 'ADMIN'].includes(claims?.role) && <Item onClick={openRejuvantKits}>Rejuvant Kits</Item>}
            <Item onClick={onClickSignOut}>Sign Out</Item>
        </Ribbon>}
    </Wrapper>

}