/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Column, Dropdown, Input, Row } from "../components/defaults";
import api from "../utils/api";
import { CloseIcon } from '../images/close.js';
import { isInvalidEmail } from "../utils/validator";
import { NotificationManager } from "react-notifications";
import { DropdownInput } from '../components/dropdown-input';

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Satoshi-Medium'
`;

const MarkChip = styled.div`
    padding: 8px 12px;
    border-radius: 10px;
    background: rgba(0,0,0,0.05);
    color: rgba(0,0,0,.8);
    font-size: 16px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
`;

const CloseIconWrapper = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: black;
    opacity: 0.8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const validateEmail = (field, value, setError) => {
    if (isInvalidEmail(value)) {
        setError(`Invalid ${field}`);
        return false;
    }
    return true;
}

const TabSelectorWrapper = styled(Row)`
    gap: 5px;
`;

const Tab = styled.div`
    ${props => props.selected ? `color: ${SELECTED_COLOR};` : 'color: rgba(0,0,0,.8);'}
    ${props => props.selected && `background: rgba(0,0,0,0.05);`};
    ${props => !props.selected && `cursor: pointer;`};
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
`;

const TabSelector = ({ tab, setTab }) => {

    return <TabSelectorWrapper>
        <Tab onClick={() => setTab('brand')} selected={tab==='brand'}>Brand</Tab>
        <Tab onClick={() => setTab('user')} selected={tab==='user'}>User</Tab>
    </TabSelectorWrapper>

}

const Wrapper = styled(Row)`
    gap: 5px;
    flex-wrap: wrap;
`;

const UserMarkersManagement = () => {
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [markers, setMarkers] = useState([]);
    const [marker, setMarker] = useState('');
    const [userData, setUserData] = useState();

    useEffect(() => {
        fetchMarkers()
    }, []);

    const fetchMarkers = async () => {
        setLoading(true);
        const res = await api.get('/admin/fetch-markers')
        setMarkers(res.data);
        setLoading(false);
    }

    const fetchUserMarkers = async () => {
        try {
            setLoading(true);
            const res = await api.get(`/admin/manage-user-markers?search=${search}`)
            setData(res.data.markers.map((marker) => ({ label: marker, value: marker })));
            setUserData(res.data);
        } catch (error) {
            const message = error?.response?.data?.title || 'An error occurred';
            NotificationManager.error(message);
        } finally {
            setLoading(false);
        }
    }

    const updateUserMarkers = async () => {
        try {
            setSubmitLoading(true);
            const payload = {
                markers: data.map((m) => m.value),
                user_id: userData.user_id
            }
            await api.post('/admin/manage-user-markers', payload)
            NotificationManager.success('User markers updated');
            setData([]);
            setUserData(null);
            setSearch('');
        } catch (error) {
            const message = error?.response?.data?.title || 'An error occurred';
            NotificationManager.error(message);
        } finally {
            setSubmitLoading(false);
        }
    }

    const addMark = () => {
        if (!marker) {
            NotificationManager.info('Please select a marker');
            return;
        }
        if (data.find((m) => m.value === marker.value)) {
            NotificationManager.info('Marker already added');
            return
        }
        setData((existing) => [...existing, marker])
        setMarker('');
    }

    const removeMark = (value) => {
        setData((existing) => existing.filter((m) => m.value !== value))
    }

    const markChip = ({ label, value, onClose }) => {
        return <MarkChip>
                {label}
                <CloseIconWrapper onClick={() => onClose(value)}>
                    <CloseIcon fill="#fff" />
                </CloseIconWrapper>
            </MarkChip>
    }

    return <Column style={{ gap: 15 }}>
        <Input 
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            label={'User Email or ID'}
            placeholder={'johndoe@mail.com'}
        />
        <Button onClick={fetchUserMarkers} disabled={!search} loading={loading}>Search</Button>
        {
            userData && <>
                <Row style={{ gap: 15 }}>
                    <DropdownInput 
                        value={marker} 
                        onChange={setMarker} 
                        options={markers} 
                        label='Markers'
                    />
                    <Column>
                        <div style={{ flex: 1 }} />
                        <Button onClick={addMark}>Add Marker</Button>
                    </Column>
                </Row>
                <Wrapper>
                    {data.map((userMaker) => markChip({ label: userMaker.label, onClose: removeMark, value: userMaker.value }))}
                </Wrapper>
                <Button disabled={!data.length} onClick={updateUserMarkers} loading={submitLoading}>Update User Markers</Button>
            </>
        }
    </Column>
}

const BrandMarkersManagement = () => {
    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [markers, setMarkers] = useState([]);
    const [marker, setMarker] = useState('');
    const [brandData, setBrandData] = useState();

    useEffect(() => {
        fetchMarkers();
        fetchBrands();
    }, []);


    const fetchMarkers = async () => {
        setLoading(true);
        const res = await api.get('/admin/fetch-markers')
        setMarkers(res.data);
        setLoading(false);
    }
    
    const fetchBrands = async () => {
        const res = await api.get('/admin/brands');
        setBrands(res.data.map((brand) => ({ label: brand.name, value: brand.id })));
    }

    const fetchBrandMarkers = async () => {
        try {
            setLoading(true);
            const res = await api.get(`/admin/manage-brand-markers?search=${search?.value}`)
            setData(res.data.markers.map((marker) => ({ label: marker, value: marker })));
            setBrandData(res.data);
        } catch (error) {
            const message = error?.response?.data?.title || 'An error occurred';
            NotificationManager.error(message);
        } finally {
            setLoading(false);
        }
    }

    const updateBrandMarkers = async () => {
        try {
            setSubmitLoading(true);
            const payload = {
                markers: data.map((m) => m.value),
                brand_id: brandData.brand_id
            }
            await api.post('/admin/manage-brand-markers', payload)
            NotificationManager.success('Brand markers updated');
            setData([]);
            setBrandData(null);
            setSearch('');
        } catch (error) {
            const message = error?.response?.data?.title || 'An error occurred';
            NotificationManager.error(message);
        } finally {
            setSubmitLoading(false);
        }
    }

    const addMark = () => {
        if (!marker) {
            NotificationManager.info('Please select a marker');
            return;
        }
        if (data.find((m) => m.value === marker.value)) {
            NotificationManager.info('Marker already added');
            return
        }
        setData((existing) => [...existing, marker])
        setMarker('')
    }

    const removeMark = (value) => {
        setData((existing) => existing.filter((m) => m.value !== value))
    }

    const markChip = ({ label, value, onClose }) => {
        return <MarkChip>
                {label}
                <CloseIconWrapper onClick={() => onClose(value)}>
                    <CloseIcon fill="#fff" />
                </CloseIconWrapper>
            </MarkChip>
    }

    const filteredMarkers = markers.filter((m) => !data.find((d) => d.value === m.value))

    return <Column style={{ gap: 15 }}>
        <DropdownInput
            value={search}
            onChange={(value) => setSearch(value)}
            label={'Brand name or ID'}
            options={brands || []}
        />
        <Button onClick={fetchBrandMarkers} disabled={!search} loading={loading}>Search</Button>
        {
            brandData && <>
                <Row style={{ gap: 15 }}>
                    <DropdownInput 
                        value={marker} 
                        onChange={setMarker} 
                        options={filteredMarkers} 
                        label='Markers'
                    />
                    <Column>
                        <div style={{ flex: 1 }} />
                        <Button onClick={addMark}>Add Marker</Button>
                    </Column>
                </Row>
                
                <Wrapper>
                    {data.map((brandMarker) => markChip({ label: brandMarker.label, onClose: removeMark, value: brandMarker.value }))}
                </Wrapper>
                <Button disabled={!data.length} onClick={updateBrandMarkers} loading={submitLoading}>Update User Markers</Button>
            </>
        }
    </Column>
}

export const MarkerSettings = () => {
    const [tab, setTab] = useState('brand');

    return <Contents>
        <Row style={{ marginBottom: 30 }}>
            <TopTitle>Manage Genetic Markers</TopTitle>
            <div style={{ flex: 1 }} />
            <TabSelector tab={tab} setTab={setTab} />
        </Row>
        {tab === 'brand' && <BrandMarkersManagement />}
        {tab === 'user' && <UserMarkersManagement />}
    </Contents>

}
