/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Column, Row } from "../components/defaults";
import api from "../utils/api";
import useAuth from "../utils/useAuth";
import { Search } from "../components/search";
import { Table, TableContext, TableContextWrapper } from "../components/table";
import { fetchBrands, fetchLaboratory, fetchTests } from "../utils/brand";
import Select from "react-dropdown-select";
import { NotificationManager } from "react-notifications";
import { BrandSelector, KitCommandContext, KitCommandContextWrapper, KitCommands } from "../components/kit-commands";


const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';
const UNSELECTED_COLOR = 'rgba(91, 99, 125, 1)';

const statusBgColors = {
    'REGISTERED': 'rgba(255, 77, 109, 1)',
    'PROCESSED': 'rgba(79, 222, 119, 1)',
    'UNASSIGNED': 'rgba(255, 77, 109, 1)',
    'IN_LAB': '#FEAF1E',
    'FLAGGED': 'rgba(255, 77, 109, 1)',
    'NEW_ORDER': 'rgba(53, 66, 255, 1)',
}

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 31px;
    margin-bottom: 23px;
    font-family: 'Satoshi-Medium'
`;


const TopRow = styled(Row)`

`;

const TabTitleWrapper = styled(Row)`
    padding:16px 22px;
    align-items: center;
    cursor: pointer;
    border-bottom: ${props => props.selected ? `1px solid ${SELECTED_COLOR}` : '1px solid transparent'};
`;

const TabTitleText = styled.div`
    font-size: 17px;
    margin-left: 8px;
    color: ${props => props.selected ?  SELECTED_COLOR : UNSELECTED_COLOR };
`;

const TabTitle = ({ selected = false, Icon, title, onClick = () => {} }) => {
    return <TabTitleWrapper selected={selected} onClick={onClick}>
        <Icon fill={selected ? SELECTED_COLOR : UNSELECTED_COLOR} />
        <TabTitleText selected={selected}>{title}</TabTitleText>
    </TabTitleWrapper>
}


const PillWrapper = styled.div`
    padding: 10px 20px;
    color: white;
    background: ${props => props.background};
    border-radius: 100px;
`;

const PillButton = styled(PillWrapper)`
    background: rgba(245, 246, 250, 1);
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
`;

const DropdownInput = ({ options, value, onChange, ...inputProps }) => {
    
    return <DropdownInputWrapper>
        <Select options={options} values={value ? [value] : []} 
        onChange={(e) => {
            onChange(e[0])
        }}
        {...inputProps} />
    </DropdownInputWrapper>

}

const DropdownInputWrapper = styled(Column)`
    * {
        outline: none;
        font-family: 'Satoshi-Regular';
    }
    .react-dropdown-select {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 10px 10px;
        outline: none;
        height: 43px;
    } 
    .react-dropdown-select-input{
        font-size: 16px;
        padding: 0px;
        font-family: 'Satoshi-Regular';
    }
`;

const Input = styled.input`
    font-size: 16px;
    font-family: 'Satoshi-Regular';
    background: rgba(249, 249, 249, 1);
    border-radius: 5px;
    border: 1px solid rgba(219, 219, 219, 1);
    padding: 10px 10px;
    height: 43px;
    outline: none;
    box-sizing: border-box;
    margin-left: 10px;
    width: 250px;
`;

const tests = [
    { label: 'Age', value: 2},
    { label: 'Folate', value: 1},
]

const CreateKit = () => {

    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState(null);
    const [barcode, setBarcode] = useState('');
    const [testType, setTestType] = useState(tests[0]);

    useEffect(() => {
        const fetch = async () => {
            setBrands(await fetchBrands())
        }
        fetch()
    }, [])

    const barcodeRef = useRef()

    useEffect(() => {
        if (brands.length) setBrand(brands[0])
    }, [brands])

    const submit = async () => {
        setLoading(true) 
        try {
            await api.post('/admin/create-kit', { barcode, brand: brand.value, test_type_id: testType.value })
            NotificationManager.success('Kit added', null, 800)
            setBarcode('')
            setTimeout(() => barcodeRef.current?.focus(), 200)
        } catch (err) {
            NotificationManager.error('Error adding kit', null, 800)
        }
        setLoading(false)
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && barcode?.length > 0) {
            submit()
        }
    };

    return <Row style={{ gap: 10 }}>
        <DropdownInput label='Brand' options={brands} value={brand} onChange={(e) => setBrand(e)} />
        <DropdownInput style={{ width: 100 }} label='Test Type' options={tests} value={testType} onChange={(e) => setTestType(e)} />
        <Input 
        disabled={loading} 
        value={barcode} 
        onChange={(e) => setBarcode(e.target.value)} 
        onKeyDown={onKeyDown} 
        ref={barcodeRef}
        placeholder="Input barcode and press enter" />
    </Row>
}

const Count = styled.div`
    align-self: flex-end;
    margin-bottom: 15px;
    color: rgba(91, 99, 125, 1);
    border-radius: 100px;
    border: 1px solid rgba(91, 99, 125, 1);
    padding: 1px 10px;
`;


export const FulfillmentContents = () => {

    const {
        setSelectedBrandKit
    } = useContext(KitCommandContext)

    const {
        query,
        setQuery,
        skip,
        limit,
        setSkip,
        fieldQuery,
        fieldSort,
        data,
        loading,
        fetchKits,
        count
    } = useContext(TableContext);

    const fetchStatus = () => [
        'UNASSIGNED',
        'REGISTERED',
        'IN_LAB',
        'PROCESSED',
        'FLAGGED',
        'NEW_ORDER'
    ].map((v) => ({ label: v, value: v }))


    const columns = (id) => [
        { id: 'code', title: 'Code', disableQuery: true },
        { id: 'laboratory_number', title: 'Lab Number', disableQuery: true },
        { id: 'bundle', title: 'Bundle', disableQuery: true },
        { id: 'shopify_order_number', title: 'Order Number', disableQuery: true },
        { id: 'shopify_order_status', title: 'Tracking Status', disableQuery: true },
        { id: 'status', disableSorting: true, title: 'Status', fetchOptions: fetchStatus, formatter: (values) => <PillWrapper background={statusBgColors[values['status']]}>{values['status']}</PillWrapper> },
        { id: 'test', title: 'Test', fetchOptions: fetchTests, disableSorting: true },
        { id: 'brand', title: 'Brand', fetchOptions: fetchBrands, formatter: (values) => 
            <BrandSelector {...values} setVisible={setSelectedBrandKit} claims={claims} />, disableSorting: true },
        { id: 'laboratory', title: 'Laboratory', fetchOptions: fetchLaboratory, disableSorting: true },
        { id: '', title: '', formatter: (values) => <KitCommands id={id} {...values} />}
    ]
    
    const { claims } = useAuth()

    useEffect(() => {
        if (claims) fetchKits();
    }, [claims, limit, query, skip, fieldSort, fieldQuery])


    return <Contents>
        <TopRow>
            <TopTitle>Fulfillment</TopTitle>
            <div style={{ flex: 1 }} />
            <CreateKit />
        </TopRow>
        <TopRow>
            <Count>Total Kits: {count}</Count>
            <div style={{ flex: 1, height: 60 }} />
            <Search setSkip={setSkip} setQuery={setQuery}/>
        </TopRow>
        <Table data={data} columns={columns} loading={loading} />
    </Contents>

}

export const Fulfillment = () => {

    return <div> 
        <TableContextWrapper>
            <KitCommandContextWrapper>
                <FulfillmentContents />
            </KitCommandContextWrapper>
        </TableContextWrapper>  
    </div>

}