
import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import { Column } from './defaults';

const Wrapper = styled(Column)`
    height: ${props => props.hasImage ? '500px' : '340px'};
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 10px;
    align-items: center;
    justify-content: center;

    p {
        font-size: 13px;
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px;
        color: rgba(0,0,0,.6);
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Label = styled.label`
    font-size: 16px;
    color: rgba(0,0,0,.6);
    margin-bottom: 11px;
`;

const Disclaimer = styled.div`
    font-size: 12px;
    color: rgba(0,0,0,.4);
`;

const Preview = styled.img`
    width: 400px;
    border-radius: 5px;
    overflow: hidden;
    object-fit: contain;
    margin-bottom: 30px;
    max-width: 80%;
`;

export function ImageInput({
    label = 'Your Brand Logo',
    style,
    existingImage,
    onSuccess = () => {},
    onError = () => {},
    onStart = () => {}
}) {


    const validateImageResolution = (file, minWidth = 1200, minHeight = 0) => {
        
        if (file.type === 'image/svg+xml') {
            return true;
        }

        return new Promise((resolve) => {
          const img = new Image();
          const objectURL = URL.createObjectURL(file);
          img.src = objectURL;
      
          img.onload = () => {
            URL.revokeObjectURL(objectURL); // Clean up the object URL
            if (img.width >= minWidth && img.height >= minHeight) {
                return resolve(true)
            } else {
                onError('Png resolution must be at least 1200 pixels wide.');
                return resolve(false)
            }
          };
        });
    };

    const setImage = async (file) => {
        if (await validateImageResolution(file)) return onSuccess(file);
    }

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        onStart()

        if (fileRejections.length > 0) {
            onError(fileRejections[0].errors[0].message);
            return;
        }
        const file = acceptedFiles[0];
        if (!file) {
            return;
        } else {
            setImage(file)
        }
    }, [])
    
    
    const {
        getRootProps, 
        getInputProps, 
        isDragActive
    } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/svg+xml': ['.svg'],
        },
        maxFiles: 1,
    })

    return (
        <Column style={style}>
        
            <Label>{label}</Label>
            <Wrapper hasImage={!!existingImage} {...getRootProps()}>
            <input {...getInputProps()} />
            {existingImage && <Preview src={existingImage} alt='Current' />}
            
            
            {
                isDragActive ?
                <p>Drop the image here ...</p> :
                <p>Drag 'n' drop your image here, or click to select image, then click on update to confirm.</p>
            }
                <Disclaimer>We only accept PNG images over 1200x800 pixels or SVG files. Please check if your image's background is transparent and there is are no empty spaces around it.</Disclaimer>
            </Wrapper>
        </Column>
    )
}