/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Button, Column, Row } from './defaults';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    input {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;
        font-family: 'Satoshi-Medium';

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const Input = React.forwardRef(({ label, ...inputProps }, ref) => {
    return  <InputWrapper>
        <input ref={ref} {...inputProps} />
    </InputWrapper>
})

const PrButton = styled(Button)`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Error = styled.div`
    color: red;
    font-size: 14px;
`;

function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function AssignToPatientModal({ 
    kit_id,
    barcode,
    setVisible,
    fetchKits,
}) {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const input = React.useRef();
    const navigate = useNavigate();

    const send = async () => {
        setLoading(true);
        setError(null)
        if (!validateEmail(email)) setError('Invalid email.')
        try {
            await api.post('/admin/kit/register', {
                email,
                id: kit_id,
            })
            fetchKits()
            setTimeout(() => {
                NotificationManager.success(`Kit ${barcode} was sucessfully associated with ${email}`);
            }, 100)
            setVisible()
        } catch (err) {
            console.log(err?.response?.data)
            if (err.response.status === 404 && err?.response?.data?.title === 'User not found') navigate(`/assign-kit/${kit_id}?email=${email}&barcode=${barcode}`)
            if (err.response.status === 400) setError("We couldn't register this kit.")
        }
        setLoading(false)
    }

    const disabled = !email;
    
    const onKeyDown = (event) => {
        if (event.key === 'Enter' && email) send()
    }

    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Assign Kit to Patient</Title>
            </TopSection>
            <FormSection>
                <Input onKeyDown={onKeyDown} ref={input} value={email} onChange={(e) => setEmail(e.target.value?.trim())} placeholder='User email' />
                <Row style={{ gap: 15, justifyContent: 'flex-end', alignItems: 'center', marginTop: 20 }}>
                    <Error>{error}</Error>
                    <div style={{ flex: 1 }} />
                    <PrButton loading={loading} onClick={send} disabled={disabled}>
                        Submit
                    </ PrButton>
                </Row>
            </FormSection>
        </Box>
    </Modal>

}