import React from 'react';

export const BrandingIcon = ({ fill='#4F59F0' }) => {
    return <svg width="23" height="23" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.0498 4.5C6.0998 4.5 4.5498 6.05 4.5498 8C4.5498 9.95 6.0998 11.5 8.0498 11.5C9.9998 11.5 11.5498 9.95 11.5498 8C11.5498 6.05 9.9998 4.5 8.0498 4.5ZM8.0498 10.5C6.6498 10.5 5.5498 9.4 5.5498 8C5.5498 6.6 6.6498 5.5 8.0498 5.5C9.4498 5.5 10.5498 6.6 10.5498 8C10.5498 9.4 9.4498 10.5 8.0498 10.5Z" fill={fill}/>
    <path d="M15.4 6.35L14 5.9L13.7 5.15L14.4 3.85C14.55 3.55 14.5 3.15 14.25 2.9L13.05 1.7C12.8 1.45 12.4 1.4 12.1 1.55L10.8 2.25L10.05 1.95L9.6 0.55C9.5 0.25 9.2 0 8.85 0H7.15C6.8 0 6.5 0.25 6.45 0.6L6 2C5.7 2.05 5.45 2.15 5.2 2.3L3.9 1.6C3.6 1.45 3.2 1.5 2.95 1.75L1.75 2.95C1.5 3.2 1.45 3.6 1.6 3.9L2.25 5.15C2.15 5.4 2.05 5.7 1.95 5.95L0.55 6.4C0.25 6.5 0 6.8 0 7.15V8.85C0 9.2 0.25 9.5 0.6 9.6L2 10.05L2.3 10.8L1.6 12.1C1.45 12.4 1.5 12.8 1.75 13.05L2.95 14.25C3.2 14.5 3.6 14.55 3.9 14.4L5.2 13.7L5.95 14L6.4 15.45C6.5 15.75 6.8 16 7.15 16H8.85C9.2 16 9.5 15.75 9.6 15.45L10.05 14L10.8 13.7L12.1 14.4C12.4 14.55 12.8 14.5 13.05 14.25L14.25 13.05C14.5 12.8 14.55 12.4 14.4 12.1L13.7 10.8L14 10.05L15.45 9.6C15.75 9.5 16 9.2 16 8.85V7.15C16 6.8 15.75 6.45 15.4 6.35ZM15 8.7L13.2 9.25L13.15 9.5L12.7 10.55L12.55 10.8L13.45 12.45L12.45 13.45L10.8 12.55L10.55 12.7C10.2 12.9 9.85 13.05 9.5 13.15L9.25 13.2L8.7 15H7.3L6.75 13.2L6.5 13.15L5.45 12.7L5.2 12.55L3.55 13.45L2.55 12.45L3.45 10.8L3.3 10.55C3.1 10.2 2.95 9.85 2.85 9.5L2.8 9.25L1 8.7V7.3L2.7 6.8L2.8 6.55C2.9 6.15 3.05 5.8 3.25 5.45L3.4 5.2L2.55 3.55L3.55 2.55L5.15 3.45L5.4 3.3C5.75 3.1 6.1 2.95 6.5 2.85L6.75 2.75L7.3 1H8.7L9.25 2.75L9.5 2.85C9.85 2.95 10.2 3.1 10.55 3.3L10.8 3.45L12.45 2.55L13.45 3.55L12.55 5.2L12.7 5.45C12.9 5.8 13.05 6.15 13.15 6.5L13.2 6.75L15 7.3V8.7Z" fill={fill}/>
    </svg>
}


