import React, { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { NotificationManager } from 'react-notifications';
import papaparse from 'papaparse'
import styled from 'styled-components'
import { Button, Column, Row } from '../components/defaults'
import api from '../utils/api'

const FormSection = styled(Column)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PrimaryButton = styled(Button)`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Wrapper = styled(Column)`
    height: ${props => props.hasImage ? '500px' : '340px'};
    width: 80%;
    margin: 10px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 10px;
    align-items: center;
    justify-content: center;

    p {
        font-size: 13px;
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px;
        color: rgba(0,0,0,.6);
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const RejuvantKits = () => {
  const [results, setResults] = useState([])
  const [file, setFile] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const onError = () => {alert('Error to process the file')}
  const onStart = () => {}
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
      onStart()
      if (fileRejections.length > 0) {
          onError(fileRejections[0].errors[0].message);
          return;
      }
      const file = acceptedFiles[0];
      if (!file) {
          return;
      } else {
          setFile(file)
      }
  }, [])

  useEffect(() => {
    readCSV(file)
  }, [file])

  const onSubmit = async () => {
    if (!results.length) {
        setError('Please select a file to process.')
        return;
    }
    setLoading(true);
    setError(null)
    try {
        await api.post('/admin/rejuvant-kits', {
            data: results
        })
        setResults([])
        setFile(null)
        setError(null)
        NotificationManager.success('File processed successfully!', 'Success', 3000);
    } catch (err) {
        console.log(err?.response?.data?.title)
        if (err?.response?.data?.title) {
            setError(err?.response?.data?.title)    
        } else {
            setError("Error to upload CSV! Check the network tab")
        }
    }
    setLoading(false)
  }

  const readCSV = async (file) => {
      if (!file) return;

      papaparse.parse(file, {
          complete: (result) => {
              const data = result.data;
              const processedResults = [];
              const headers = data[0];
              if (!headers || headers.length < 10) {
                  setError('Invalid file format. Please check the file and try again.');
                  return;
              }

              data.forEach((row) => {
                 const email = row[7].toLowerCase();
                 if (email === "custemail") {
                     return;
                 }
                 let rowData = {}
                 headers.forEach((header, index) => {
                    rowData[header] = row[index];
                 });
                 processedResults.push(rowData);
              });
              console.log(processedResults)
              setResults(processedResults);
          },
          header: false,
          skipEmptyLines: true,
      });
  }

  const {
      getRootProps, 
      getInputProps, 
      isDragActive
  } = useDropzone({
      onDrop,
      accept: {
          'text/*': ['.csv']
      },
      maxFiles: 1,
  })

  return <FormSection>
      <Wrapper {...getRootProps()}>
          <input {...getInputProps()} />
          {
              isDragActive ?
              <p>Drop the csv here ...</p> :
              <p>Drag 'n' drop your file here, or click to select csv, then click on update to confirm.</p>
          }
          {
              file && file?.name
          }
      </Wrapper>
      {!!results.length && <span style={{ color: 'green' }}>File processed. Click to submit!</span>}
      <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
          <PrimaryButton disabled={!results || loading} loading={loading} onClick={() => onSubmit()}>Confirm</PrimaryButton>
      </Row>
      {error && <span style={{ color: 'red' }}>{error}</span>}
  </FormSection>
}