/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Column, Input, Row } from "../components/defaults";
import api from "../utils/api";
import { NotificationManager } from "react-notifications";
import { DropdownInput } from '../components/dropdown-input';

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Satoshi-Medium'
`;

export const UserSettings = () => {
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [brands, setBrands] = useState([]);
    const [userData, setUserData] = useState();
    const [brand, setBrand] = useState();

    useEffect(() => {
        fetchBrands()
    }, []);

    const fetchBrands = async () => {
        setLoading(true);
        const res = await api.get('/admin/brands')
        setBrands(res.data.map((m) => ({ label: m.name, value: m.id })));
        setLoading(false);
    }

    const fetchUserData = async () => {
        try {
            setLoading(true);
            const res = await api.get(`/admin/user-brand?search=${search}`)
            setUserData(res.data);
            if (res.data?.brand_id) {
                const userBrand = brands.find((m) => m.value === res.data.brand_id);
                if (userBrand) setBrand({  label: userBrand.label, value: userBrand.value });
            }
        } catch (error) {
            const message = error?.response?.data?.title || 'An error occurred';
            NotificationManager.error(message);
        } finally {
            setLoading(false);
        }
    }

    const updateUserBrand = async () => {
        try {
            setSubmitLoading(true);
            const payload = {
                brand_id: brand.value,
                user_id: userData.id
            }
            await api.post('/admin/user-brand', payload)
            NotificationManager.success('User brand updated');
            setUserData(null);
            setSearch('');
        } catch (error) {
            const message = error?.response?.data?.title || 'An error occurred';
            NotificationManager.error(message);
        } finally {
            setSubmitLoading(false);
        }
    }

    return <Contents>
        <Row style={{ marginBottom: 30 }}>
         <TopTitle>Manage User Brand</TopTitle>
        </Row>

        <Column style={{ gap: 15 }}>
            <Input 
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                label={'User Email or ID'}
                placeholder={'johndoe@mail.com'}
            />
            <Button onClick={fetchUserData} disabled={!search} loading={loading}>Search</Button>
            {
                userData && <>
                    <DropdownInput 
                    value={brand} 
                    onChange={(e) => setBrand({  label: e.label, value: e.value })} 
                    options={brands} 
                    label='Brand'
                    />
                    <Button disabled={!brand || brand.value === userData.brand_id} onClick={updateUserBrand} loading={submitLoading}>Update User Brand</Button>
                </>
            }
        </Column>
    </Contents>

}