/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import Select from 'react-dropdown-select';
import useAuth from '../utils/useAuth';
import api from '../utils/api';
import loadingIcon from '../images/3-dots-move-purple.svg'
import moment from 'moment';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    input {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;
        font-family: 'Satoshi-Medium';

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const Input = React.forwardRef(({ label, ...inputProps }, ref) => {
    return  <InputWrapper>
        <input ref={ref} {...inputProps} />
    </InputWrapper>
})

const Button = styled.div`
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
    font-size: 16px;
`;

const PrimaryButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Loading = styled(Column)`
    height: 200px;
    align-items: center;
    justify-content: center;
`;

const NoContents = styled(Column)`
    height: 200px;
    align-items: center;
    justify-content: center;
    color: rgba(0,0,0,.5);
`

const User = styled.div`
    color: rgba(5, 31, 115, 1);
    font-size: 16px;
`;

const Date = styled.div`
    color: rgba(5, 31, 115, 1);
    font-size: 12px;
`;

const Text = styled.div`
    color: rgba(91, 99, 125, 1);
    font-size: 16px;
    margin-top: 6px;
    padding-bottom: 10px; 
    border-bottom: 1px solid rgba(0,0,0,0.1)
`;

const Note = ({ date, user, text }) => {
    return <Column style={{ marginBottom: 10 }}>
    <Row>
        <User>{user.first_name} {user.last_name}</User>
        <div style={{ flex: 1 }} />
        <Date>{moment(date).format('DD-MM-YYYY HH:MM')}</Date>
    </Row>
    <Text>{text}</Text>
    </Column>
}

const NotesWrapper = styled.div`
    max-height: 300px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding-right: 15px;
`;

const Info = styled.div`
    font-size: 12px;
    font-family: 'Satoshi-Regular'
`;

export default function Notes({ 
    kit,
    setVisible,
    barcodeInputRef
}) {

    const [sendDisabled, setSendDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [text, setText] = useState('');
    const input = React.useRef();

    const { claims } = useAuth();

    const fetchNotes = async () => {
        setLoading(true)
        const result = (await api.get(`/admin/notes?id=${kit?.id}`)).data;
        setNotes(result?.notes || []);
        setLoading(false)
    }

    useEffect(() => {
        if (input?.current) input.current.focus();
        const handleInput = (event) => {
            if (event.key === 'Escape') {
                setVisible(null);
                if (barcodeInputRef.current) barcodeInputRef.current.focus()
            }
        }
        window.addEventListener('keydown', handleInput);
        return () => {
            window.removeEventListener('keydown', handleInput);
        };
    }, [])

    useEffect(() => {
        if (!!claims) fetchNotes();
    }, [claims])

    const send = async () => {
        setSendDisabled(true)
        await api.post('/admin/notes', {
            text,
            id: kit?.id,
        })
        await fetchNotes()
        setSendDisabled(false)
        setText('')
    }
    
    const disabled = !text;
    
    const onKeyDown = (event) => {
        if (event.key === 'Enter' && text) send()
    }

    
    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Leave a Note</Title>
            </TopSection>
            <FormSection>
                {loading && <Loading><img alt='loading' src={loadingIcon} /></Loading>}
                {!loading && notes?.length === 0 && <NoContents>No notes available</NoContents>}
                <NotesWrapper>
                    {!loading && notes?.map((note) => <Note {...note}/>)}
                </NotesWrapper>
                <Input onKeyDown={onKeyDown} ref={input} value={text} onChange={(e) => setText(e.target.value)} placeholder='Start typing' />
                <Row style={{ gap: 15, alignItems: 'center', marginTop: 20 }}>
                    <Info>You can press esc to exit this screen and go back to Barcode Input</Info>
                    <div style={{ flex: 1 }} />
                    <Button onClick={() => setVisible(null)}>
                        Cancel
                    </Button>
                    <PrimaryButton onClick={send} disabled={disabled || sendDisabled}>
                        Submit
                    </PrimaryButton>
                </Row>
            </FormSection>
        </Box>
    </Modal>

}