import { useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { clearIdentity } from './heap';


export const authorizedRoles = {
  'ADMIN': {
    validate: (route) => {
      return true
    }
  },
  'SUPER_ADMIN': {
    validate: (route) => {
      return true
    }
  },
  'TECHNICIAN': {
    authorizedRoutes: ['/scan', '/sign-in'],
    defaultRoute: '/scan',
    validate: function (route) {
      return this.authorizedRoutes.includes(route)
    }
  }
}


const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState({});
  const [claims, setClaims] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Indicates that we're waiting for the auth state to be resolved
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const verifyAdmin = async () => {
    let claims = (await getAuth().currentUser?.getIdTokenResult())?.claims;
    if (claims['override_claims']) claims = {
      ...claims['override_claims'],
      reset_override: true
    };
    
    if (!isLoading && !!claims && !authorizedRoles[claims?.role]) {
      setCurrentUser(false);
      await getAuth().signOut();
      localStorage.removeItem('token');
      NotificationManager.error(`You're not authorized to access this page. Please contact support if you have any inquiries.`)
      return
    }

    const routeIsValidToRole = authorizedRoles[claims?.role]['validate'](pathname)
    if (!routeIsValidToRole) {
      navigate(authorizedRoles[claims?.role]['defaultRoute'], { replace: true })
      return
    }
    setClaims(claims);
  }

  useEffect(() => {
    if (currentUser) verifyAdmin()
  }, [currentUser])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setCurrentUser(!!user);
      setCurrentUserData(user)
      if (!user) clearIdentity()
      setIsLoading(false); // Auth state is resolved, update loading state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { currentUser, isLoading, claims, currentUserData };
};

export default useAuth;